<template lang="pug">
  div.app
    div.app__container
      div.app__inner
        div.app__img
          img(
            :src="App"
            alt="telephone"
          )
        div.app__info
          h3.title.app__title Повна сумісність із застосунком STARLINK
          ul.app__list
            li(v-for='(item, index) in appList' v-bind:key='index' class="app__item") {{ item.title }}
          div.app__links
            a(href="https://apps.apple.com/us/app/starlink/id1537177988").link
              | Завантажити на iOS
            a(href="https://play.google.com/store/apps/details?id=com.starlink.mobile").link
              | Завантажити на Android
</template>

<script>
// images
import App from "@/assets/images/app-img.png"

export default {
  name: 'AppBlock',
  data () {
    return {
      App,
      appList: [
        { title: 'Налаштування обладнання.' },
        { title: 'Перевірка перешкод сигналу та усунення проблем із підключенням.' },
        { title: 'Перегляд статистики підключення.' },
        { title: 'Ідентифікація і керування підключеними пристроями.' },
        { title: 'Технічна підтримка Starlink.' }
      ]
    }
  }
}
</script>
